<template>
  <div
    class="py-4"
    :style="dataItemStyle"
    v-bind="dataItemProperties"
  >
    <component
      :is="component"
      :listKey="listKey"
      :row="row"
      :name="name"
      :index="index"
      :config="config"
    ></component>

    <!-- 資料動作 -->
    <action
      v-if="config.action"
      :listKey="listKey"
      :row="row"
      :name="name"
      :index="index"
      :config="config"
    ></action>
  </div>
</template>

<script>
export default {
  props: {
    listKey: String,
    row: Object,
    name: String,
    index: Number,
    config: Object,
  },
  computed: {
    type() {
      return this.config.type
    },
    component() {
      // 自訂元件
      if(this.config.component) return this.config.component

      // 標準元件
      const standardComponent = this.$options.components[`list-table-${this.type}`]
      if(standardComponent) return standardComponent

      // 預設為文字元件
      return this.$options.components[`list-table-text`]
    },
    dataItemStyle() {
      return {
        width: !this.config.width ? 'auto' : this.config.width,
        maxWidth: !this.config.maxWidth ? '160px' : this.config.maxWidth,
      }
    },
    dataItemProperties() {
      if(typeof this.config.dataItemProperties != 'function') return null
      return this.config.dataItemProperties(this.row)
    },
  },
  components: {
    action: () => import('@/components/list/tableMode/action.vue'),
    'list-table-data-action': () => import('@/components/list/listDataAction.vue'),
    'list-table-text': () => import('@/components/list/tableMode/listTableTypes/text.vue'),
    'list-table-photo': () => import('@/components/list/tableMode/listTableTypes/photo.vue'),
    'list-table-switch': () => import('@/components/list/tableMode/listTableTypes/switch.vue'),
    'list-table-array': () => import('@/components/list/tableMode/listTableTypes/array.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
